body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .smooth-dnd-container.horizontal  {
  display: block;
 
 }
 .smooth-dnd-container.horizontal1  {
  display: grid !important;
 
 } */
.text-color-green {
  color: green;
}

.nav-item {
  list-style-type: none;
}
.chat-send-icons{
  cursor: pointer;
  width: 35px;
}

@media (max-width: 768px) {
  .btn.dropdown-toggle span.text-black {
    font-size: 8px;
  }

  .btn-tog {
    padding: 8px 8px;
  }

  .flx-direc {
    margin-left: 50;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .text-black.mr-2 {
    display: none;
  }

  .btn.dropdown-toggle.status {
    display: none;
  }

  .form-group.row.sta {
    /* margin-left: 600%; */
    display: none;
  }

  .dropdown-item.pro {
    display: none;
  }
}

@media (max-width: 1115px) {
  .status-container {
    display: none;
  }

  .mobile-h {
    display: none;
  }

  .mobile-hide {
    display: none;
  }

  .tnb {
    margin-left: 15px;
  }

  .tnnb {
    margin-left: 42% !important;
  }

  .chat-lf {
    margin-left: -500% !important;
  }

  .profi {
    margin-left: 615%;
    margin-bottom: 20%;
  }

  .dcal {
    margin-left: 30%;
  }

  .dchat {
    margin-left: 0%;
  }

  .dchatt {
    margin-left: 8%;
  }

  .profil {
    margin-left: 450%;
    margin-bottom: 5%;
  }

  .nav-item.nav-profile.dropdown.show.mobile-support-container {
    margin-left: 40%;
    margin-right: -65%;
  }

  .proi {
    margin-left: 400%;
    margin-bottom: 20%;
    width: 2px;
    height: 2px;
  }

  .psta {
    margin-left: 58%;
  }

  .stwidth {
    width: 65%;
    padding: 2%;
    margin-top: 20%;
  }

  .custom-height-button {
    border: 2px solid grey;
    border-radius: 10px;
    height: 40px;
    margin-top: 0%;
    overflow: hidden;
  }

  .info-c {
    height: 100%;
  }

  .gs {
    display: none;
  }

  .sp {
    margin-top: 20%;
  }

}

.fade-in-phrase {
  font-size: 16px;
  background: white;
  padding: 13px;
  border-radius: 15px;
  opacity: 0;
  animation: fadeInAnimation 1.5s ease-in-out forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
